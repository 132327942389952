<template>
  <div>

    <!-- basic modal -->
    <b-modal
      id="modal-filter"
      title="Filter"
      centered
      scrollable
      @ok="updateMap()"
    >
      <b-form>
        <b-form-group
          label="Listing Type"
          label-for="listing-type"
        >
          <v-select
            v-model="selected.listingType"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="value"
            :options="options.listingType"
          />
        </b-form-group>
        <b-form-group
          label="Availability"
          label-for="availability"
        >
          <v-select
            v-model="selected.availability"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="value"
            :options="options.availability"
          />
        </b-form-group>
        <b-form-group
          label="Home Type"
          label-for="hometype"
        >
          <v-select
            v-model="selected.homeType"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="value"
            :options="options.homeType"
          />
        </b-form-group>
        <b-form-group
          label="Days"
          label-for="days"
        >
          <v-select
            v-model="selected.days"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="value"
            :options="options.days"
          />
        </b-form-group>
        <b-form-group
          label="Price Min"
          label-for="pricemin"
        >
          <v-select
            v-model="selected.priceMin"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="options.priceRange"
          />
        </b-form-group>
        <b-form-group
          label="Price Max"
          label-for="pricemax"
        >
          <v-select
            v-model="selected.priceMax"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="options.priceRange"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <l-map
      ref="myMap"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      @ready="mapOnReady()"
      @moveend="updateMap()"
    >
      <l-control class="example-custom-control">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-filter
          variant="outline-primary"
        >Filter</b-button>
      </l-control>
      <l-tile-layer
        :url="url"
      />
      <v-marker-cluster>
        <l-marker
          v-for="listing in listings"
          :key="listing['listing-id']"
          :lat-lng="[listing.location.position.coordinates[1], listing.location.position.coordinates[0]]"
        >
          <l-popup>
            <b-card
              :img-src="listing.media['image-urls'][0]"
              img-top
              img-alt="card img"
              :title="showPrice(listing.financial)"
              class="mb-3"
            >
              <b-card-text>
                <a
                  target="_blank"
                  :href="`/apps/listing/view/${listing.location.city.replace(/ /g, '_')}/${listing.location['full-address-short'].replace(/ /g, '_')}/${listing['listing-id']}`"
                >
                  {{ showAddress(listing.location) }}
                </a>
              </b-card-text>
              <b-card-text>
                {{ showAttributes(listing.attributes) }}
              </b-card-text>
              <b-card-text>
                <small class="text-muted">{{ listing.timeline['modified-at'] }}</small>
              </b-card-text>
            </b-card>
          </l-popup>
        </l-marker>
      </v-marker-cluster>
    </l-map>
  </div>

</template>

<script>
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import {
  LMap, LTileLayer, LPopup, LMarker, LControl,
} from 'vue2-leaflet'
// eslint-disable-next-line no-unused-vars
import moment from 'moment'
import 'leaflet/dist/leaflet.css'
import { Icon } from 'leaflet'
import {
  BButton, BForm, BFormGroup, BFormInput, BCard, BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  // eslint-disable-next-line global-require
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  // eslint-disable-next-line global-require
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  // eslint-disable-next-line global-require
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
/* eslint-disable global-require */

export default {
  components: {
    LMap,
    LTileLayer,
    LPopup,
    LMarker,
    LControl,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    BForm,
    BButton,
    vSelect,
    BCard,
    BCardText,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 8,
      center: [43.780918, -79.421371],
      mapOptions: {
        zoomControl: false,
      },
      listings: [],
      selected: {
        listingType: { value: 'Sale' },
        availability: { value: 'Active' },
        homeType: { value: 'Detached' },
        days: { value: 30 },
        priceMin: 0,
        priceMax: 100000000,
      },
      options: {
        listingType: [{ value: 'Sale' }, { value: 'Lease' }],
        availability: [{ value: 'Active' }, { value: 'Unavailable' }],
        homeType: [{ value: 'Detached' }, { value: 'Semi-detached' }, { value: 'Townhouse' }, { value: 'Condo/Apartment' }],
        days: [{ value: 30 }, { value: 60 }, { value: 90 }, { value: 120 }, { value: 180 }],
        priceRange: [0, 100000, 150000, 200000, 250000, 300000, 350000, 400000, 450000, 500000, 1000000, 1500000, 2000000, 100000000],
      },
    }
  },
  created() {
    this.$root.$refs.listingMap = this
    store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
    store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: 'hidden' })
  },
  mounted() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 1000)
  },
  methods: {
    mapOnReady() {
      this.listings = []
      this.map = this.$refs.myMap.mapObject
    },
    showPrice(financial) {
      if (financial.sold > 0) {
        return `$${financial.sold.toLocaleString()}`
      }
      return `$${financial.listed.toLocaleString()}`
    },
    showAddress(location) {
      if (location['unit-number']) {
        return `${location['unit-number']} ${location['full-address-short'].toUpperCase()}`
      }
      return location['full-address-short'].toUpperCase()
    },
    showAttributes(attributes) {
      return `${attributes.bedrooms} Beds - ${attributes.bathrooms} Baths - ${attributes['square-footage'].size || 0} ${attributes['square-footage'].unit}`
    },
    async getListings(geoLocation, extraParams) {
      const filter = {
        where: {
          'location.position': {
            geoWithin: {
              $geometry: {
                type: 'Polygon',
                coordinates: [[
                  [parseFloat(geoLocation.west), parseFloat(geoLocation.south)],
                  [parseFloat(geoLocation.west), parseFloat(geoLocation.north)],
                  [parseFloat(geoLocation.east), parseFloat(geoLocation.north)],
                  [parseFloat(geoLocation.east), parseFloat(geoLocation.south)],
                  [parseFloat(geoLocation.west), parseFloat(geoLocation.south)],
                ]],
              },
            },
          },
          // 'metadata.source': 'WEB',
          'availability.status': extraParams.availability[0] || 'a',
          'attributes.class': 'residential',
          'attributes.type': null,
          'attributes.ownership-type': null,
          'listing-type': extraParams.listingType || 'sale',
          'timeline.modified-at': {
            gt: moment().subtract(extraParams.days, 'days').toDate(),
          },
        },
        order: 'timeline.modified-at DESC',
        limit: 250,
        fields: {
          'listing-id': true,
          media: true,
          'listing-type': true,
          location: true,
          financial: true,
          id: true,
          timeline: true,
          attributes: true,
        },
      }

      if (extraParams.homeType === 'condo/apartment') {
        filter.where['attributes.ownership-type'] = 'condominium'
        delete filter.where['attributes.type']
      } else {
        filter.where['attributes.ownership-type'] = 'freehold'
        filter.where['attributes.type'] = extraParams.homeType
      }

      filter.where['financial.listed'] = {
        between: [extraParams.priceMin, extraParams.priceMax],
      }

      // const res = await fetch(`https://mongocms-subsystems-listing-ms.local.fastdns.io/api/listings?filter=${JSON.stringify(filter)}`, {
      const res = await fetch(`http://localhost:36011/api/listings?filter=${JSON.stringify(filter)}`, {
        headers: {
          'x-api-key': 'mongocms',
        },
      })
      const data = await res.json()
      return data
    },
    async updateMap() {
      const { _southWest, _northEast } = this.map.getBounds()
      const geoLocation = {
        south: _southWest.lat,
        west: _southWest.lng,
        north: _northEast.lat,
        east: _northEast.lng,
      }
      const extraParams = {
        homeType: this.selected.homeType.value.toLowerCase(),
        listingType: this.selected.listingType.value.toLowerCase(),
        availability: this.selected.availability.value.toLowerCase()[0],
        days: this.selected.days.value,
        priceMin: this.selected.priceMin,
        priceMax: this.selected.priceMax,
      }
      const listings = await this.getListings(geoLocation, extraParams)
      this.listings = listings.filter(listing => listing.location.position.type === 'Point' && listing.location.position.coordinates !== [0, 0])
    },
  },
}
</script>

<style lang="scss">
.vue2leaflet-map{
  &.leaflet-container{
    position: fixed;
    top: 63px;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
.leaflet-cluster-anim .leaflet-marker-icon, .leaflet-cluster-anim .leaflet-marker-shadow {
  -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
  -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
  -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
  transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
  /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
  -webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out, -webkit-stroke-opacity 0.3s ease-in;
  -moz-transition: -moz-stroke-dashoffset 0.3s ease-out, -moz-stroke-opacity 0.3s ease-in;
  -o-transition: -o-stroke-dashoffset 0.3s ease-out, -o-stroke-opacity 0.3s ease-in;
  transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}
.marker-cluster-small {
  background-color: rgba(181, 226, 140, 0.6);
}
.marker-cluster-small div {
  background-color: rgba(110, 204, 57, 0.6);
}

.marker-cluster-medium {
  background-color: rgba(241, 211, 87, 0.6);
}
.marker-cluster-medium div {
  background-color: rgba(240, 194, 12, 0.6);
}

.marker-cluster-large {
  background-color: rgba(253, 156, 115, 0.6);
}
.marker-cluster-large div {
  background-color: rgba(241, 128, 23, 0.6);
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
  background-color: rgb(181, 226, 140);
}
.leaflet-oldie .marker-cluster-small div {
  background-color: rgb(110, 204, 57);
}

.leaflet-oldie .marker-cluster-medium {
  background-color: rgb(241, 211, 87);
}
.leaflet-oldie .marker-cluster-medium div {
  background-color: rgb(240, 194, 12);
}

.leaflet-oldie .marker-cluster-large {
  background-color: rgb(253, 156, 115);
}
.leaflet-oldie .marker-cluster-large div {
  background-color: rgb(241, 128, 23);
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}
.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;

  text-align: center;
  border-radius: 15px;
  font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.marker-cluster span {
  line-height: 30px;
}
</style>
